import image404 from '../public/assets/images/404 illustration.png';
import Image from 'next/legacy/image';
import Link from 'next/link';

import styles from './page_not_found.module.css';

const page404 = () => (
  <>
    <div className={styles.app__page_not_found}>
      <div
        className={styles.app__page_not_found_message}>
        <div
          className={styles.app__page_not_found_title}>
          <h1>404</h1>
        </div>
        <div className={styles.app__page_not_found_subtitle}>
          <h2>Oops! Page Not Found</h2>
        </div>
        <div className={styles.app__page_not_found_text}>
          <p>We can&apos;t seem to find the page you are looking for ...
            We suggest you back to home.</p>
        </div>
        <div>
          <Link href='/' className='custom__button'>
            Back to Home
          </Link>
        </div>
      </div>
      <div className={styles.app__page_not_found_img}>
        <Image
          src={image404}
          width={462}
          height={437}
          alt={`Page Not Found`}/>
      </div>
    </div>
  </>
);

export default page404;
